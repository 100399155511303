import { useEffect } from 'react'

/**
 * Custom hook that detects clicks outside of a specified element.
 *
 * @param ref - The ref object that points to the element to detect clicks outside of.
 * @param onOutsideClick - The callback function to be executed when a click outside of the element occurs.
 */
export function useClickOutside(ref: React.RefObject<HTMLElement>, onOutsideClick: () => void) {
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [])

    function handleClickOutside(event: MouseEvent) {
        const menuElement = ref.current
        const parentElement = ref.current?.parentElement

        if (menuElement && menuElement.contains(event.target as Node)) {
            return
        }

        if (menuElement && !menuElement.contains(event.target as Node) && parentElement && !parentElement.contains(event.target as Node)) {
            onOutsideClick()
        }
    }
}
