import type { AreaCleaningStatus, Occupancy } from '@shared/firestore-structs'
import type { HousekeepingOverviewProjectionStruct_v2 } from '@shared/projections-v2'
import { remapToNumber } from '@shared/projections/projection-ticks'
import { areaOverview_v2 } from '@shared/projections/v2/areaOverview'
import { mapToAreaProjection_v2 } from '@shared/projections/v2/mapToAreaProjection'
import moment, { type Moment } from 'moment'
import { atom, selector, selectorFamily } from 'recoil'
import { overrideableState } from '../../../../infra/overrideable-state'
import { searchDb, setData } from '../../../../infra/search'
import type { AreaBoxGroupFormatted } from '../../../../types/housekeeping.types'
import type { HousekeepingOverviewCombined } from '../../../../user-actions/housekeeping-overview-actions'
import { cleaningStatuses, formatHousekeepingOverview, groupSorting, occupancyList } from '../../../../utils/housekeeping-utils'
import { areasHousekeepingSelectorWithAccess, currentOrgSelector, currentUser, rulesAtom } from '../../../auth/state/login'
import { type AssigningFilterMode, massAssigningBarOpenAtom, massAssigningFilterModeAtom } from './mass-assigning-state'
import { getTeamWorkloadList } from './team-workload-data'

const housekeepingOverviewDateState = atom<string>({
    key: 'housekeeping-overview-startDate',
    default: moment().format('YYYY-MM-DD')
})

export const housekeepingOverviewDate = selector<Moment>({
    key: 'housekeepingOverviewDate',
    get: ({ get }) => {
        const timeZone = get(currentOrgSelector).timezone
        const date = get(housekeepingOverviewDateState)
        return moment.tz(date, timeZone)
    },
    set: ({ set }, newValue) => {
        if (moment.isMoment(newValue)) {
            set(housekeepingOverviewDateState, newValue.format('YYYY-MM-DD'))
        }
    }
})

const {
    resolverValue: housekeepingOverviewResolver,
    frontendSetter,
    backendSetter
} = overrideableState<HousekeepingOverviewProjectionStruct_v2[0], HousekeepingOverviewCombined[0]>({
    id: 'housekeeping-overview',
    dateSelector: housekeepingOverviewDate,
    assembleOutput: (backend, override) => ({ ...backend, ...override }),
    overridesMerger: (prev, curr) => ({ ...prev, ...curr }),
    overrideDiscarder: (backend, override, id) => {
        const result = remapToNumber(backend.ticks) >= override.ticks ? 'discard' : 'keep'
        console.debug(
            `<Housekeeping overview: ${id}>Comparing backend ticks ${backend.ticks} with override ticks ${override.ticks}, result: ${result}`
        )
        return result
    },
    backendSelector: backend =>
        selector<HousekeepingOverviewCombined>({
            key: 'housekeeping-overview',
            get: ({ get }) => {
                const data = get(backend)!
                const areas = get(areasHousekeepingSelectorWithAccess)
                const rules = get(rulesAtom)
                const currentOrganization = get(currentOrgSelector)
                const startDate = get(housekeepingOverviewDate)
                if (!data || !areas || !rules || !currentOrganization) {
                    return {}
                }

                const cleanAreas = Object.values(areas).filter(area => !data[area.key])
                const removingDroppedAreas = Object.entries(data).reduce((acc, [key, value]) => {
                    if (!areas[key]) {
                        return acc
                    }
                    return Object.assign(acc, {
                        [key]: { ...value, name: areas[key]?.name, note: areas[key]?.note ?? '', group: areas[key]?.group ?? 'Unknown' }
                    })
                }, {} as HousekeepingOverviewCombined)
                return cleanAreas.reduce((acc, area) => {
                    const { updatedArea, isPriority, leadBooking, result } = mapToAreaProjection_v2({
                        area: { ...area },
                        startOfDayInTZ: moment(startDate).startOf('day').valueOf(),
                        org: currentOrganization,
                        bookings: [],
                        activities: [],
                        rules: rules,
                        currentTask: undefined,
                        dailyComments: [],
                        lastCleaningTask: null
                    })
                    const areaOverviewCalculated = areaOverview_v2({
                        timezone: currentOrganization.timezone,
                        bookings: [],
                        leadBooking: leadBooking,
                        area: updatedArea[0],
                        isPriority: isPriority,
                        areaSummary: result,
                        dailyComments: []
                    })
                    const postProcessed = Object.fromEntries(
                        Object.entries(areaOverviewCalculated).map(([key, value]) => {
                            return [key, { ...value, name: area.name, note: area.note ?? '', group: area.group ?? 'Unknown' }]
                        })
                    )

                    return { ...acc, ...postProcessed }
                }, removingDroppedAreas)
            }
        })
})
export const housekeepingOverview = housekeepingOverviewResolver
export const areaBoxGroupsBackend = backendSetter
export const housekeepingOverviewLocalOverride = frontendSetter

export const priorityFilterValueAtom = atom<boolean>({
    key: 'housekeeping-overview-priorityFilterValue',
    default: false
})
export const searchValueAtom = atom<{ searchTerm: string } | 'all'>({
    key: 'housekeeping-overview-searchValue',
    default: 'all'
})

export const occupancyFilterValueAtom = atom<Occupancy[]>({
    key: 'housekeeping-overview-occupancyFilterValue',
    default: occupancyList
})

export const cleaningStatusFilterValuesAtom = atom<AreaCleaningStatus[]>({
    key: 'housekeeping-overview-cleaningStatusFilterValues',
    default: cleaningStatuses
})

export const areaGroupsFilterAtom = atom<string>({
    key: 'housekeeping-overview-areaGroupsFilterAtom',
    default: 'All'
})

export const occupancyFilterAtom = atom<boolean>({ key: 'housekeeping-overview-occupancyFilterAtom', default: false })
export const cleaningStatusFilterAtom = atom<boolean>({ key: 'housekeeping-overview-cleaningStatusFilterAtom', default: false })

export const areaBoxGroupsState = selector<AreaBoxGroupFormatted[]>({
    key: 'housekeeping-overview-areaBoxGroups',
    get: ({ get }) => {
        const data = get(housekeepingOverview)
        const areas = get(areasHousekeepingSelectorWithAccess)
        const user = get(currentUser)

        if (!data || !areas) {
            return []
        }
        const preparedAreaBoxGroups = formatHousekeepingOverview(data, areas, user).filter(group => group.areaBoxSummaries.length > 0)

        setData(
            preparedAreaBoxGroups
                .map(group =>
                    group.areaBoxSummaries.map(area => ({
                        groupName: group.group,
                        areaName: area.name,
                        areaKey: area.areaKey,
                        guestNames: '',
                        assignedTo: area.assignedTo.map(a => a.name + a.initials)
                    }))
                )
                .flat()
        )
        return preparedAreaBoxGroups
    }
})

export const areaBoxGroupsSelector = selector<AreaBoxGroupFormatted[]>({
    key: 'housekeeping-overview-filteredAreaBoxGroups-selector',
    get: ({ get }) => {
        let areaBoxGroups = get(areaBoxGroupsState)
        const priorityFilterValue = get(priorityFilterValueAtom)
        const occupancyFilterValue = get(occupancyFilterValueAtom)
        const search = get(searchValueAtom)
        const massAssigningBarOpen = get(massAssigningBarOpenAtom)
        const massAssigningFilterMode = get(massAssigningFilterModeAtom)
        const cleaningStatusFilterValues = get(cleaningStatusFilterValuesAtom)
        const areaGroupsFilter = get(areaGroupsFilterAtom)

        if (priorityFilterValue) {
            areaBoxGroups = filterPriorityAreas(areaBoxGroups)
        }

        areaBoxGroups = filterByOccupancy(areaBoxGroups, occupancyFilterValue)

        if (massAssigningBarOpen && massAssigningFilterMode !== 'all') {
            areaBoxGroups = filterByAssigningMode(areaBoxGroups, massAssigningFilterMode)
        }

        if (areaGroupsFilter !== 'All') {
            areaBoxGroups = areaBoxGroups.filter(group => group.group === areaGroupsFilter)
        }

        areaBoxGroups = filterByCleaningStatus(areaBoxGroups, cleaningStatusFilterValues)

        if (search !== 'all') {
            const searchTerm = search.searchTerm
            const keys = searchDb(searchTerm)
            areaBoxGroups = filterSearchAreas(areaBoxGroups, keys)
        }
        areaBoxGroups.sort((a, b) => groupSorting(a.group, b.group))
        return areaBoxGroups
    }
})

export const areaBoxGroupSelectorFamily = selectorFamily({
    key: 'housekeeping-areaBoxGroup-selectorFamily',
    get:
        (groupName: string) =>
        ({ get }) => {
            const groups = get(areaBoxGroupsSelector)
            const group = groups.find(g => g.group === groupName)
            return group?.areaBoxSummaries
        }
})

export const areaBoxGroupsNamesSelector = selector<{ name: string; size: number }[]>({
    key: 'housekeeping-overview-areaBoxGroups-selector',
    get: ({ get }) => {
        return get(areaBoxGroupsState).map(group => ({ name: group.group, size: group.areaBoxSummaries.length }))
    }
})

export const areaBoxGroupsNamesFilteredSelector = selector<{ name: string; size: number }[]>({
    key: 'housekeeping-overview-areaBoxGroupsNames-filtered-selector',
    get: ({ get }) => {
        return get(areaBoxGroupsSelector).map(group => ({ name: group.group, size: group.areaBoxSummaries.length }))
    }
})

export const areasBoxesSelector = selector<(HousekeepingOverviewCombined[0] & { key: string })[]>({
    key: 'housekeeping-overview-areasBoxes-selector',
    get: ({ get }) => {
        return get(areaBoxGroupsSelector).reduce<(HousekeepingOverviewCombined[0] & { key: string })[]>((acc, group) => {
            acc.push(...group.areaBoxSummaries.map(summary => ({ ...summary, key: summary.areaKey, group: group.group })))
            return acc
        }, [])
    }
})

const noFiltersAreasBoxesSelector = selector<(HousekeepingOverviewCombined[0] & { key: string })[]>({
    key: 'housekeeping-overview-areasBoxes-selector-no-filters',
    get: ({ get }) => {
        return get(areaBoxGroupsState).reduce<(HousekeepingOverviewCombined[0] & { key: string })[]>((acc, group) => {
            acc.push(...group.areaBoxSummaries.map(summary => ({ ...summary, key: summary.areaKey, group: group.group })))
            return acc
        }, [])
    }
})

export const cleaningTasksSelector = selector<HousekeepingOverviewCombined[0][]>({
    key: 'housekeeping-overview-cleaningTasks-selector',
    get: ({ get }) => {
        return get(noFiltersAreasBoxesSelector).reduce<HousekeepingOverviewCombined[0][]>((acc, areaBox) => {
            if (areaBox.currentTask) {
                acc.push(areaBox)
            }
            return acc
        }, [])
    }
})

export const areasQuantitySelector = selector<number>({
    key: 'housekeeping-overview-areasQuantitySelector',
    get: ({ get }) => get(areaBoxGroupsSelector).reduce((acc, g) => acc + g.areaBoxSummaries.length, 0)
})

export const areaGroupsSelector = selector<string[]>({
    key: 'housekeeping-overview-areaGroupsSelector',
    get: ({ get }) => get(areaBoxGroupsState).map(g => g.group)
})

function filterSearchAreas(areaBoxGroups: AreaBoxGroupFormatted[], areaKeys: string[]): AreaBoxGroupFormatted[] {
    return areaBoxGroups.reduce((acc, group) => {
        const prioritySummaries = group.areaBoxSummaries.filter(summary => areaKeys.includes(summary.areaKey))
        if (prioritySummaries.length > 0) {
            acc.push({
                ...group,
                areaBoxSummaries: prioritySummaries
            })
        }
        return acc
    }, [] as AreaBoxGroupFormatted[])
}
function filterPriorityAreas(areaBoxGroups: AreaBoxGroupFormatted[]): AreaBoxGroupFormatted[] {
    return areaBoxGroups.reduce((acc, group) => {
        const prioritySummaries = group.areaBoxSummaries.filter(summary => summary.priority)
        if (prioritySummaries.length > 0) {
            acc.push({
                ...group,
                areaBoxSummaries: prioritySummaries
            })
        }
        return acc
    }, [] as AreaBoxGroupFormatted[])
}

function filterByOccupancy(areaBoxGroups: AreaBoxGroupFormatted[], occupancyFilterValues: Occupancy[]): AreaBoxGroupFormatted[] {
    return areaBoxGroups.reduce((acc, group) => {
        const filteredSummaries = group.areaBoxSummaries.filter(summary => {
            if (occupancyFilterValues.includes('checkin') && ['checkin', 'turnover'].includes(summary.occupancy)) {
                return true
            }

            if (occupancyFilterValues.includes('checkout') && ['checkout', 'turnover'].includes(summary.occupancy)) {
                return true
            }

            return occupancyFilterValues.includes(summary.occupancy)
        })
        if (filteredSummaries.length > 0) {
            acc.push({
                ...group,
                areaBoxSummaries: filteredSummaries
            })
        }
        return acc
    }, [] as AreaBoxGroupFormatted[])
}

function filterByCleaningStatus(
    areaBoxGroups: AreaBoxGroupFormatted[],
    cleaningStatusFilterValues: AreaCleaningStatus[]
): AreaBoxGroupFormatted[] {
    return areaBoxGroups.reduce((acc, group) => {
        const filteredSummaries = group.areaBoxSummaries.filter(summary => cleaningStatusFilterValues.includes(summary.cleaningStatus))
        if (filteredSummaries.length > 0) {
            acc.push({
                ...group,
                areaBoxSummaries: filteredSummaries
            })
        }
        return acc
    }, [] as AreaBoxGroupFormatted[])
}

function filterByAssigningMode(areaBoxGroups: AreaBoxGroupFormatted[], massAssigningFilterMode: AssigningFilterMode) {
    return areaBoxGroups.reduce((acc, group) => {
        const filteredSummaries = group.areaBoxSummaries.filter(summary => {
            if (massAssigningFilterMode === 'un-assigned') {
                return !summary.currentTask || summary.currentTask.status === 'open'
            } else if (massAssigningFilterMode === 'assigned') {
                return !!summary.currentTask && summary.currentTask.status === 'assigned'
            }
            return true
        })
        if (filteredSummaries.length > 0) {
            acc.push({
                ...group,
                areaBoxSummaries: filteredSummaries
            })
        }
        return acc
    }, [] as AreaBoxGroupFormatted[])
}

export const teamWorkloadListSelector = selectorFamily({
    key: 'housekeeping-overview-teamWorkloadList-selector',
    get:
        (showGroups: boolean) =>
        ({ get }) => {
            return getTeamWorkloadList(get(cleaningTasksSelector), showGroups)
        }
})

export const areFiltersAppliedSelector = selector({
    key: 'housekeeping-overview-areFiltersApplied-selector',
    get: ({ get }) => {
        const priorityFilterValue = get(priorityFilterValueAtom)
        const occupancyFilterValue = get(occupancyFilterValueAtom)
        const cleaningStatusFilterValues = get(cleaningStatusFilterValuesAtom)
        const areaGroupsFilter = get(areaGroupsFilterAtom)
        return (
            priorityFilterValue ||
            occupancyFilterValue.length !== occupancyList.length ||
            cleaningStatusFilterValues.length !== cleaningStatuses.length ||
            areaGroupsFilter !== 'All'
        )
    }
})
