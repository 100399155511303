import type { Firebase } from '@shared/firebase'
import { sortByName } from '@shared/helpers'
import { useEffect, useState } from 'react'
import { useCurrentOrg } from '../../auth/hooks/use-auth-state'

interface OrganizationInterface {
    organizationKey: string
    organizationName: string
}

export interface SelectedOrgInterface {
    value: string
    label: string
}

export function useChangeOrganization(firebase: Firebase) {
    const { key: orgKey, name: orgName } = useCurrentOrg()

    const [organizations, setOrganizations] = useState<{ value: string; label: string }[]>([])
    const [selectedOrg, setSelectedOrg] = useState<SelectedOrgInterface | undefined>({ value: orgKey, label: orgName })

    const [isLoading, setIsLoading] = useState(false)
    const [initialScrollIndex, setInitialScrollIndex] = useState(0)

    const currentUserOrganizations = firebase.functions().httpsCallable('getCurrentUserOrganizations') as () => Promise<{
        data: OrganizationInterface[]
    }>
    const changeOrganizationCloudFunc = firebase.functions().httpsCallable('userChangeOrganization')

    const getOrgs = async () => {
        setIsLoading(true)

        try {
            const { data } = await currentUserOrganizations()

            const convertedData = data
                .map(org => ({ value: org.organizationKey, label: org.organizationName }))
                .sort((a, b) => sortByName(a.label, b.label))

            setOrganizations(convertedData)
            setInitialScrollIndex(convertedData.findIndex(org => org.value === selectedOrg?.value) || 0)
        } catch (error) {
            console.error('Error getting organizations', error)
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        getOrgs()
    }, [])

    const changeOrganization = async (org: SelectedOrgInterface) => {
        setIsLoading(true)

        try {
            await changeOrganizationCloudFunc({ organizationKey: org.value })
            setSelectedOrg(org)
        } catch (error) {
            console.error('Error changing organization', error)
        } finally {
            setIsLoading(false)
        }
    }

    return { organizations, selectedOrg, changeOrganization, isLoading, initialScrollIndex }
}
