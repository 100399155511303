import { useI18nContext } from '@shared-snap/i18n/i18n-react'
import { UI } from '@shared-snap/snap/registry/ui-elements-registry'
import type React from 'react'
import { useCallback, useMemo, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { Controller, useFormContext } from 'react-hook-form'

export type Image = File & { preview: string }

interface ImagesDropBoxProps {
    images?: Image[]
    setImages: (images: Image[]) => void
    deleteImage?: (index: number) => void
    previewShown?: boolean
    disabled?: boolean
    size?: 'md' | 'lg'
    setLoading?: (loading: boolean) => void
}

interface FieldImagesDropboxProps {
    name: string
    previewShown?: boolean
    disabled?: boolean
}

interface PreviewBoxProps {
    name: string
    preview: string
    index: number
    deleteImage?: (index: number) => void
}

export function ImagesDropBox({
    images = [],
    setLoading,
    setImages,
    deleteImage,
    disabled,
    previewShown = true,
    size = 'md'
}: ImagesDropBoxProps) {
    const {
        LL: {
            issues: { newIssue }
        }
    } = useI18nContext()
    const onDrop = useCallback(
        (acceptedImages: File[]) => {
            setLoading && setLoading(true)
            const imagesWithPreviewAndKey = acceptedImages.map(image =>
                Object.assign(image, {
                    preview: URL.createObjectURL(image)
                })
            )
            setImages(imagesWithPreviewAndKey)
        },
        [setImages]
    )
    const sizeNumber = useMemo(() => (size === 'md' ? 32 : 64), [size])

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

    return (
        <div className={`flex ${previewShown ? `w-full h-${sizeNumber}` : `w-${sizeNumber} h-${sizeNumber}`} gap-1 cursor-pointer`}>
            <div
                className={`flex flex-col gap-2 justify-center text-center border border-2 border-dashed rounded-lg ${
                    previewShown ? `w-${sizeNumber}` : 'w-full'
                }`}
                {...getRootProps()}>
                <input className="hidden" type="file" disabled={disabled} {...getInputProps()} />

                <UI.Icon icon="specta-plus" color="snap-silver" />
                <UI.Text size="xs" color="snap-silver" align="center">
                    {isDragActive ? newIssue.drop() : newIssue.drag()}
                </UI.Text>
            </div>
            {previewShown && (
                <div className="flex w-full h-full gap-2 overflow-x-auto">
                    <UI.List
                        items={images}
                        render={({ name, preview }, i) => (
                            <PreviewBox key={name + i} name={name} preview={preview} index={i} deleteImage={deleteImage} />
                        )}
                    />
                </div>
            )}
        </div>
    )
}

export function FieldImagesDropbox({ name, disabled, previewShown = true }: FieldImagesDropboxProps) {
    const { control } = useFormContext()

    return (
        <Controller
            control={control}
            name={name}
            render={({ field: { onChange, value } }) => (
                <ImagesDropBox
                    disabled={disabled}
                    images={value || []}
                    setImages={newImages => {
                        const newValue = value ? [...value, ...newImages] : newImages
                        onChange(newValue)
                    }}
                    deleteImage={i => {
                        const newValue = value.filter((_: any, index: number) => index !== i)
                        onChange(newValue)
                    }}
                    previewShown={previewShown}
                />
            )}
        />
    )
}

function PreviewBox({ name, preview, index, deleteImage }: PreviewBoxProps) {
    const [deleteButtonIsShown, setDeleteButtonIsShown] = useState(false)

    const onMouseEnter = () => {
        setDeleteButtonIsShown(true)
    }

    const onMouseLeave = () => {
        setDeleteButtonIsShown(false)
    }

    const onDeleteButton = (event: React.MouseEvent<HTMLImageElement>) => {
        event.stopPropagation()
        deleteImage && deleteImage(index)
    }

    return (
        <div className="relative flex flex-col w-32 h-full" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            <img className="w-full h-full" src={preview} alt={name} />
            <span className="w-full overflow-hidden text-xs text-snap-silver overflow-ellipsis whitespace-nowrap">{name}</span>
            {deleteButtonIsShown && deleteImage && (
                <i
                    onClick={onDeleteButton}
                    className="absolute z-10 cursor-pointer text-sweeply-red specta-trash top-1 right-2 hover:opacity-70"
                />
            )}
        </div>
    )
}
