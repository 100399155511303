import type { Size } from '@shared-snap/snap/registry/ui-elements-registry'
import type { UserStruct } from '@shared/firestore-structs'
import { useMemo } from 'react'
import { InitialsCircle } from '../initials-circle/initials-circle'
import Tooltip from '../tooltip'

function AssignedContacts({
    assignedContacts,
    size
}: {
    assignedContacts: Pick<UserStruct, 'key' | 'name' | 'initials'>[] | null
    size?: Size
}) {
    if (!assignedContacts?.length) return null
    const tooltipUserNames = useMemo(() => assignedContacts.map(user => user.name), [assignedContacts]) ?? []
    return (
        <>
            <div className="flex flex-row" data-tooltip-id={`assigned-${assignedContacts[0].key + tooltipUserNames.length}}-tooltip`}>
                {assignedContacts.slice(0, assignedContacts.length > 3 ? 2 : 3).map((contact, index) => (
                    <div key={contact.key} className={`border-[3px] border-white rounded-full ${index === 0 ? '' : '-ml-1.5'}`}>
                        <InitialsCircle initials={contact.initials} size={size} />
                    </div>
                ))}
                {assignedContacts.length > 3 && (
                    <div className="border-[3px] border-white rounded-full ml-[-6px]">
                        <InitialsCircle initials={`+${assignedContacts.length - 2}`} backgroundColor="snap-silver" size={size} />
                    </div>
                )}
            </div>
            <Tooltip id={`assigned-${assignedContacts[0].key + tooltipUserNames.length}}-tooltip`} text={tooltipUserNames} />
        </>
    )
}

export default AssignedContacts
