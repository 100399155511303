import type { Firebase, User } from '@shared/firebase'
import type { OrgStruct, UserStruct } from '@shared/firestore-structs'
import type { AreasProjected_v2 } from '@shared/projections-v2'
import { getRulesQuery } from '@shared/queries/data'
import { userIsAllowed } from '@shared/roles/roles'
import type { RolePermissions } from '@shared/roles/roles-types'
import { getAllPlans } from '@shared/subscriptions/get-all-plans'
import { getCurrentActiveOrgForPhoneNumberQuery, getUser, increaseIssueCounter } from '@shared/user-data'
import { useEffect } from 'react'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { getAreas } from '../../../api/queries'
import {
    areasBackendSetter,
    currentOrg,
    currentOrgSelector,
    currentUser,
    isAuthenticatedAtom,
    loggedInLoading,
    loggedInUser,
    rulesAtom,
    sideBarOpenAtom,
    subscriptionAtom,
    usersSelector
} from '../state/login'

function remapAndFilterAreas(areas: AreasProjected_v2[]) {
    return areas
        .filter(a => a.visible && a.displayCleaningStatus)
        .reduce(
            (acc, area) => {
                acc[area.key] = { ...area, occupancy: area.occupancy ?? 'vacant' }
                return acc
            },
            {} as { [key: string]: AreasProjected_v2 }
        )
}

export function useAuthListener(onAuthChangedListener: (listener: (uer: User | null) => void) => () => void, firebase: Firebase) {
    const setLoading = useSetRecoilState(loggedInLoading)
    const setLoggedInUser = useSetRecoilState(loggedInUser)
    const setIsAuthenticated = useSetRecoilState(isAuthenticatedAtom)
    const setOrg = useSetRecoilState<OrgStruct | null>(currentOrg)
    const setUsers = useSetRecoilState(usersSelector)
    const setAreas = useSetRecoilState(areasBackendSetter)
    const setRules = useSetRecoilState(rulesAtom)
    const setSubscription = useSetRecoilState(subscriptionAtom)

    useEffect(() => {
        console.log('Starting listening to auth state changes')
        let userCurrentOrgUnsubscribe: () => void = () => {
            console.log('No phone number listener')
        }
        let unsubscribeOrgAreas: () => void = () => {
            console.log('Unsubscribing from areas...')
        }
        const getOrgContext = async (user: UserStruct) => {
            const [org, users, areas, rules, plans] = await Promise.all([
                firebase.firestore().collection<OrgStruct>('organizations').doc(user.organizationKey).get(),
                firebase
                    .firestore()
                    .collection<UserStruct>('users')
                    .where('organizationKey', '==', user.organizationKey)
                    .where('visible', '==', true)
                    .get(),
                getAreas(firebase.firestore(), user.organizationKey).get(),
                getRulesQuery(firebase.firestore(), user.organizationKey).get(),
                getAllPlans(firebase.firestore())
            ])

            const orgData = org.data() ?? null

            const areasData = remapAndFilterAreas(areas.data()?.areas ?? [])
            const usersData = users.docs.map(d => d.data())
            const rulesData = rules.docs.map(d => d.data())
            const subscriptionData = plans.find(plan => plan.key === orgData?.subscription) ?? null
            return { user, orgData, usersData, areasData, rulesData, subscriptionData }
        }

        const unsubscribeAuthChanges = onAuthChangedListener(async authUser => {
            if (authUser) {
                userCurrentOrgUnsubscribe()
                userCurrentOrgUnsubscribe = getCurrentActiveOrgForPhoneNumberQuery(firebase.firestore(), authUser).onSnapshot(snapshot => {
                    const user = snapshot?.data()
                    console.log(`Auth phone number userkey: ${user?.userKey}`)
                    if (user) {
                        getUser(firebase.firestore(), user.userKey)
                            .then(user => getOrgContext(user))
                            .then(({ user, orgData, usersData, subscriptionData, rulesData, areasData }) => {
                                setOrg(orgData)
                                setUsers(usersData)
                                setAreas(areasData)
                                setRules(rulesData)
                                setSubscription(subscriptionData)
                                setLoggedInUser(user)
                                setIsAuthenticated(true)
                                setLoading(false)
                                unsubscribeOrgAreas = getAreas(firebase.firestore(), user.organizationKey).onSnapshot(snapshot => {
                                    const data = snapshot?.data()
                                    if (data?.areas) {
                                        setAreas(remapAndFilterAreas(data.areas))
                                    }
                                })
                            })
                            .catch(e => {
                                console.error(
                                    `Error getting user data ${user.userKey} while watching for phone number: ${authUser.phoneNumber}`,
                                    e
                                )
                                setIsAuthenticated(false)
                                setLoggedInUser(null)
                                setLoading(false)
                            })
                    } else {
                        console.error(`No authPhoneNumbers user found for phone number ${authUser.phoneNumber}`)
                        setIsAuthenticated(false)
                        setLoggedInUser(null)
                        setLoading(false)
                    }
                })
            } else {
                userCurrentOrgUnsubscribe()
                setIsAuthenticated(false)
                setLoggedInUser(null)
                setLoading(false)
            }
        })
        console.log('Auth listener started')
        return () => {
            console.log(`Auth listener stopping`)
            unsubscribeAuthChanges()
            unsubscribeOrgAreas()
            userCurrentOrgUnsubscribe()
        }
    }, [])
}

export function useAuthState() {
    const isAuthenticated = useRecoilValue(isAuthenticatedAtom)
    const loginLoading = useRecoilValue(loggedInLoading)
    const org = useRecoilValue(currentOrg)

    const result = loginLoading ? 'loading' : isAuthenticated ? (org ? 'loggedin' : 'loading') : 'not-loggedin'
    console.log(`Auth state: ${result}`)
    return result
}

export function useCurrentUser() {
    return useRecoilValue(currentUser)
}

export function useIncrementIssueCounter(firebase: Firebase) {
    const setUser = useSetRecoilState(loggedInUser)
    const currentUser = useCurrentUser()

    return async () => {
        const issueCounter = await increaseIssueCounter(firebase, currentUser)
        setUser(prev => {
            if (!prev) return prev

            return {
                ...prev,
                issueCounter: issueCounter
            }
        })
    }
}

export function useCurrentOrg() {
    return useRecoilValue(currentOrgSelector)
}

export function useCurrentSubscription() {
    return useRecoilValue(subscriptionAtom)
}

export function usePermission() {
    const user = useRecoilValue(currentUser)
    const organization = useRecoilValue(currentOrgSelector)
    const subscription = useRecoilValue(subscriptionAtom)

    const havePermission = (permission: RolePermissions) => {
        if (subscription) {
            return userIsAllowed(permission, user, organization, subscription)
        } else return false
    }

    return havePermission
}

export function useSidebar() {
    return useRecoilState(sideBarOpenAtom)
}
