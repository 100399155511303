import { isFeatureOn } from '@shared/feature-toggles'
import type { Firebase, FirebaseFirestore, WriteBatch } from '@shared/firebase'
import type { AreaStruct, AssignedUserInfo, OrgStruct, RuleOverride, UserStruct } from '@shared/firestore-structs'
import type { AreaSummaryStruct_v2, ByAreaKey, HousekeepingOverviewProjectionStruct_v2, OmitMetadata } from '@shared/projections-v2'
import { createTask, generateTaskId, setTaskUpdate } from '@shared/task-data'
import { TASK_CLEANING_DEFAULT_NAME } from '@shared/txt-constants'
import moment, { type Moment } from 'moment-timezone'
import { getApi } from '../api/frontend-api'
import { createActivity } from './area-summary-user-actions'

export type HousekeepingOverviewCombined = ByAreaKey<
    HousekeepingOverviewProjectionStruct_v2[0] & {
        note: string
        name: string
        group: string
    },
    'with-metadata'
>

type HousekeepingOverviewUserAction = {
    isAllowed: () => boolean
    applyAction: (
        input: {
            overview: HousekeepingOverviewCombined
            areas: Readonly<ByAreaKey<AreaStruct, 'with-metadata'>>
            taskName: string
        },
        org: OrgStruct,
        date: Moment,
        firebase: Firebase,
        ...args: any[]
    ) => {
        io: Promise<void>
        areas?: { [key: string]: Partial<Omit<AreaSummaryStruct_v2, 'ticks'>> }
        overview: { [areaKey: string]: Partial<OmitMetadata<HousekeepingOverviewProjectionStruct_v2[0]>> }
    }
}

function assignToUser<
    T extends HousekeepingOverviewProjectionStruct_v2[0] & {
        note: string
    } & Pick<OrgStruct, 'key' | 'organizationKey'>
>(
    firebase: FirebaseFirestore,
    currentArea: T,
    areas: Readonly<ByAreaKey<AreaStruct, 'with-metadata'>>,
    assignedTo: AssignedUserInfo[],
    currentUser: UserStruct,
    date: moment.Moment,
    customTaskName: string,
    batch: WriteBatch | null = null,
    ruleOverride?: RuleOverride
) {
    const startDate = moment(date).startOf('day').valueOf()
    const activity = createActivity(
        firebase,
        currentArea.assignedTo ?? [],
        assignedTo,
        currentUser,
        currentArea.key,
        currentArea.organizationKey,
        moment(date)
    )
    const assignedTo1 = assignedTo.map(user => ({ key: user.key, name: user.name, initials: user.initials }))
    const taskName = customTaskName ? customTaskName : (currentArea.activeRule.name ?? TASK_CLEANING_DEFAULT_NAME)

    const task = currentArea.currentTask
        ? {
              ...currentArea.currentTask,
              name: taskName,
              assignedTo: assignedTo1,
              status: assignedTo1.length === 0 ? ('open' as const) : ('assigned' as const)
          }
        : {
              key: generateTaskId(firebase),
              assignedTo: assignedTo1,
              name: taskName,
              startDate,
              status: assignedTo1.length === 0 ? ('open' as const) : ('assigned' as const)
          }
    const result = {
        assignedTo: assignedTo1,
        currentTask: {
            key: assignedTo1.length === 0 ? null : task.key,
            status: task.status
        }
    }
    const io = async () => {
        if (currentArea.currentTask) {
            await setTaskUpdate(
                firebase,
                currentUser,
                task.key,
                {
                    name: taskName,
                    assignedTo: assignedTo1,
                    status: assignedTo1.length ? 'assigned' : 'open'
                },
                batch,
                activity
            )
        } else {
            const startDate = date.startOf('day')
            await createTask<'cleaning'>(
                firebase,
                {
                    ...areas[currentArea.key], // this area info is at the time of the snapshot
                    occupancy: currentArea.occupancy,
                    cleaningStatus: currentArea.cleaningStatus,
                    guestCheckedIn: currentArea.guestCheckedIn,
                    guestCheckedOut: currentArea.guestCheckedOut
                },
                currentUser,
                'cleaning',
                {
                    assignedTo: assignedTo1,
                    name: taskName,
                    startDate
                },
                batch,
                activity,
                currentArea.activeRule?.checklistTasks?.map(t => ({ checked: false, name: t })) ?? null,
                task.key,
                ruleOverride
            )
        }
    }
    return { result, io: io, forIo: { task, activityKey: activity.key, activityCreated: activity.created, taskName } }
}

export const housekeepingOverviewUserActions = {
    'mass-assign': {
        isAllowed: () => true,
        applyAction: (
            input,
            org,
            date,
            firebase: Firebase,
            assignedTo: AssignedUserInfo[],
            areaKeys: string[],
            currentUser: UserStruct,
            ruleOverride?: RuleOverride
        ) => {
            const startDate = moment(date).startOf('day')
            const areas = input.overview

            const areasToAssignTo = Object.entries(areas).filter(([areaKey]) => areaKeys.includes(areaKey))

            const assgnmentResultData = areasToAssignTo.map(([areaKey, area]) => {
                const p = assignToUser(
                    firebase.firestore(),
                    {
                        ...area,
                        key: areaKey,
                        organizationKey: currentUser.organizationKey
                    },
                    input.areas,
                    assignedTo,
                    currentUser,
                    startDate,
                    input.taskName,
                    null,
                    ruleOverride
                )

                return { areaKey, updated: p.result, io: p.io, forId: p.forIo }
            })

            const runBackendAssignment = async () => {
                const api = await getApi(firebase)
                await api.housekeeping['assign-tasks'].$post({
                    json: {
                        organizationId: currentUser.organizationKey,
                        assignedTo,
                        date: moment(startDate).startOf('day').valueOf(),
                        areas: Object.fromEntries(
                            assgnmentResultData.map(({ areaKey, forId }) => [
                                areaKey,
                                {
                                    taskKey: forId.task.key,
                                    taskName: forId.taskName,
                                    activityKey: forId.activityKey,
                                    activityCreated: forId.activityCreated
                                }
                            ])
                        )
                    }
                })
                console.log(`Assigned ${assignedTo.length} users to ${areaKeys.length} areas`)
            }
            const overviewOverrides = assgnmentResultData.reduce(
                (acc, { areaKey, updated }) => {
                    acc[areaKey] = {
                        assignedTo: updated.assignedTo,
                        currentTask:
                            updated.currentTask.key === null
                                ? null
                                : {
                                      key: updated.currentTask.key,
                                      status: updated.currentTask.status
                                  }
                    }
                    return acc
                },
                {} as { [areaKey: string]: Partial<HousekeepingOverviewProjectionStruct_v2[0]> }
            )
            const areaSummaryOverrides = assgnmentResultData.reduce(
                (acc, { areaKey, updated }) => {
                    acc[areaKey] = {
                        assigned: updated.assignedTo
                    }
                    return acc
                },
                {} as { [key: string]: Partial<Omit<AreaSummaryStruct_v2, 'ticks'>> }
            )

            return {
                io: isFeatureOn(org, 'mass-assign-backend')
                    ? runBackendAssignment()
                    : Promise.all(assgnmentResultData.map(({ io }) => io())).then(() => {}),
                overview: overviewOverrides,
                areas: areaSummaryOverrides
            }
        }
    }
    // eslint-disable-next-line prettier/prettier
} satisfies { [name: string]: HousekeepingOverviewUserAction }
